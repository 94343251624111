import React from "react"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

import SEO from "../components/shared/Seo"
import Layout from "../components/shared/Layout"
import ContactsSection from "../sections/Contacts"

const ContactsPage = () => (
    <Layout>
        <ReactNotification />
        <SEO title="Контакты" />
        <ContactsSection />
    </Layout>
)

export default ContactsPage
