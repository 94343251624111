import React from "react"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Slide from "react-reveal/Slide"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import Text from "../../components/shared/Text"
import Social from "../../components/shared/Social"
import ContactForm from "../../components/ContactForm"
import {
    Left,
    Right,
    Section,
    PhotoImgWrapper,
    GrayBlock,
    YellowBlock,
    GrayBlockWrapper,
    YellowBlockWrapper,
} from "./styled"

const ContactsSection = () => {
    const data = useStaticQuery(graphql`
        query {
            photo: file(
                relativePath: { eq: "assets/images/photo.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 377, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidPhoto = get(data, "photo.childImageSharp.fluid", {})

    return (
        <div className="container">
            <Section>
                <Left>
                    <PhotoImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidPhoto}
                                alt="Фото Ольги Зиляневой. Сидит на стуле, облокотившись на спинку локтями и пристально смотрит вперед."
                            />
                        </Zoom>
                    </PhotoImgWrapper>
                    <GrayBlockWrapper>
                        <Slide left>
                            <GrayBlock />
                        </Slide>
                    </GrayBlockWrapper>
                    <YellowBlockWrapper>
                        <Fade>
                            <YellowBlock />
                        </Fade>
                    </YellowBlockWrapper>
                </Left>

                <Right>
                    <Fade bottom>
                        <Text variant="h1" style={{ marginBottom: 20 }}>
                            КОНТАКТЫ
                        </Text>
                        <Social style={{ marginBottom: 30 }} />
                        <ContactForm />
                    </Fade>
                </Right>
            </Section>
        </div>
    )
}

export default ContactsSection
