import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    display: flex;
    position: relative;
    padding: 160px 0 100px 0;

    ::after {
        content: "";
        clear: both;
        display: table;
    }
`

export const Left = styled.div`
    ${media.md} {
        float: left;
        width: 50%;
        margin-top: 100px;
        position: relative;
    }
`

export const Right = styled.div`
    width: 100%;

    ${media.md} {
        max-width: 375px;
        float: right;
        width: 50%;
    }
`

export const PhotoImgWrapper = styled.div`
    ${media.md} {
        position: absolute;
        display: block;
        width: 377px;
        left: -70px;
        top: 0;
    }
    ${media.lg} {
        left: 0;
    }
`

export const GrayBlockWrapper = styled.div`
    ${media.lg} {
        position: absolute;
        display: block;
        height: 590px;
        width: 510px;
        left: -133px;
        z-index: -1;
        top: -60px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(207, 6%, 70%, 1);
`

export const YellowBlockWrapper = styled.div`
    ${media.lg} {
        position: absolute;
        display: block;
        height: 590px;
        width: 430px;
        z-index: -2;
        right: 125px;
        top: 0;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const YellowBlock = styled.div`
    background-color: hsla(96, 5%, 80%, 1);
`
